
import { computed, defineComponent, inject } from '@nuxtjs/composition-api'
import { StudentReadNotificationsInjectionKey } from '@/student/layouts/default.vue'

const FOOTER_ITEMS = [
  {
    icon: 'mdi-calendar',
    title: '指導',
    to: '/calendar'
  },
  {
    icon: 'mdi-bell-outline',
    title: 'おしらせ',
    to: '/notifications'
  },
  {
    icon: 'mdi-book-open-blank-variant',
    title: '教材',
    to: '/materials'
  },
  {
    icon: 'mdi-file-chart-outline',
    title: '成績',
    to: '/grades'
  }
] as const

export default defineComponent({
  setup() {
    const studentReadNotifications = inject(StudentReadNotificationsInjectionKey)!

    const unreadNotifications = computed(() => {
      return studentReadNotifications.value.filter(studentReadNotification => !studentReadNotification.readDate)
    })
    return { FOOTER_ITEMS, unreadNotifications }
  }
})
